import React, { useEffect } from 'react';

interface IProps {
    message: string;
    type: 'success' | 'error' | 'warning';
    duration?: number; // Durata in millisecondi (default: 4000ms)
    onClose: () => void;
}

function Notification({ message, type, duration = 4000, onClose }: IProps) {
    useEffect(() => {
        const timer = setTimeout(() => {
            onClose();
        }, duration);

        // Pulizia del timer al termine dell'effetto
        return () => {
            clearTimeout(timer);
        };
    }, [duration, onClose]);

    // Colori basati sul tipo di notifica
    const typeStyles = {
        success: 'bg-green-500 text-white',
        error: 'bg-red-500 text-white',
        warning: 'bg-yellow-500 text-black',
    };

    return (
        <div
            className={`max-w-64 fixed bottom-4 right-4 p-4 rounded-lg shadow-lg transition-transform transform translate-y-0 ${typeStyles[type]} z-50`}
        >
            {message}
        </div>
    );
};

export default Notification;
