import React from 'react';
import { PageType } from '../types';
import LevelsGroupsTable from '../components/tables/LevelsGroupsTable';
import SingleLevelGroup from '../components/SingleLevelGroup';

interface IProps {
	type: PageType;
}

function LevelsGroups({ type }: IProps) {
	return (
		<section className='w-full h-full'>

			{type === PageType.ALL && <LevelsGroupsTable />}
			{type === PageType.SINGLE && <SingleLevelGroup />}
			{/* {type === PageType.CREATE && <CreateLevelGroup />} */}

		</section>
	);
}

export default LevelsGroups;
