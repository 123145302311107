interface IProps {
    x: number;
    y: number;
    position: { horizontal: 'right' | 'left'; vertical: 'top' | 'bottom' };
}

function calculatePopupPosition(e: React.MouseEvent): IProps {
    const rect = e.currentTarget.getBoundingClientRect();
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    // Calculate available space
    const spaceRight = screenWidth - rect.right;
    const spaceLeft = rect.left;
    const spaceTop = rect.top;
    const spaceBottom = screenHeight - rect.bottom;

    // Determine horizontal position
    let horizontal: 'right' | 'left' = 'right';
    if (spaceRight < 200 && spaceLeft > 200) {
        horizontal = 'left';
    }

    // Determine vertical position
    let vertical: 'top' | 'bottom' = 'bottom';
    if (spaceBottom < 150 && spaceTop > 150) {
        vertical = 'top';
    }

    return {
        x: rect.left + window.scrollX,
        y: rect.top + window.scrollY,
        position: { horizontal, vertical },
    };
}

export default calculatePopupPosition;
