import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Notification from './utils/Notification';
import { MdDelete, MdEdit, MdEditNote, MdOpenInBrowser, MdOutlineCopyAll, MdOutlineScreenShare } from 'react-icons/md';

function SingleLevelGroup() {
    const { id } = useParams();

    const [notification, setNotification] = useState<{ message: string; type: 'success' | 'error' | 'warning' } | null>(null);

    const showNotification = (message: string, type: 'success' | 'error' | 'warning') => {
        setNotification({ message, type });
    };

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                showNotification("Link copiato negli appunti!", 'success');
            })
            .catch((error) => {
                showNotification(`Errore nella copia del link: ${error}`, 'error');
            });
    };

    return (
        <section>

            {notification && (
                <Notification
                    message={notification.message}
                    type={notification.type}
                    onClose={() => setNotification(null)}
                />
            )}

            <h1 className='h1'>Pacchetto di Livelli</h1>

            <div className='flex items-center gap-3 my-6'>
                <h5 className='h5'>Pacchetto:</h5>
                <span>Pacchetto {id}</span>
            </div>

            <div className='flex items-center justify-between gap-6 mb-10'>
                <button className='btn btn-lg'>Modifica <MdEdit size={20} /></button>
                <button className='btn btn-lg'>Pubblica <MdOutlineScreenShare size={20} /></button>
                <button className='btn btn-lg btn-danger'>Elimina <MdDelete size={20} /></button>
            </div>

            <div className='grid grid-cols-7 gap-6'>

                <div className='col-span-3'>

                    <div className='w-full p-6 bg-white rounded-2xl shadow-lg'>

                        <div className='w-full flex items-center justify-between'>
                            <h3 className='h3'>Elenco Livelli associati</h3>
                            <button className='btn'>Modifica <MdEdit size={18} /></button>
                        </div>

                        <table className='my-3'>
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Obiettivo</th>
                                </tr>
                            </thead>
                            <tbody>
                                {[
                                    { name: 'Livello 1', goal: 'Obiettivo 1' },
                                    { name: 'Livello 2', goal: 'Obiettivo 2' },
                                    { name: 'Livello 3', goal: 'Obiettivo 3' },
                                    { name: 'Livello 4', goal: 'Obiettivo 4' },
                                    { name: 'Livello 5', goal: 'Obiettivo 5' },
                                    { name: 'Livello 6', goal: 'Obiettivo 6' },
                                    { name: 'Livello 7', goal: 'Obiettivo 7' },
                                ].map((group, index) => (
                                    <tr key={index}>
                                        <td>{group.name}</td>
                                        <td>{group.goal}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </div>

                </div>

                <div className='col-span-4'>

                    <div className='w-full p-6 bg-white rounded-2xl shadow-lg'>

                        <div className='w-full flex items-center justify-between'>
                            <h3 className='h3'>Elenco Dipendenti associati</h3>
                            <button className='btn'>Modifica <MdEdit size={18} /></button>
                        </div>

                        <table className='my-3'>
                            <thead>
                                <tr>
                                    <th>Cognome</th>
                                    <th>Nome</th>
                                    <th>Reparto</th>
                                    <th>Matricola</th>
                                </tr>
                            </thead>
                            <tbody>
                                {[
                                    { Surname: 'Rossi', Name: 'Mario', Department: 'Amministrazione', Badge: '123456' },
                                    { Surname: 'Verdi', Name: 'Luca', Department: 'Produzione', Badge: '654321' },
                                    { Surname: 'Bianchi', Name: 'Paolo', Department: 'Vendite', Badge: '456789' },
                                    { Surname: 'Gialli', Name: 'Giovanni', Department: 'Marketing', Badge: '987654' },
                                    { Surname: 'Neri', Name: 'Andrea', Department: 'Risorse Umane', Badge: '789456' },
                                    { Surname: 'Arancioni', Name: 'Giuseppe', Department: 'Logistica', Badge: '654987' },
                                    { Surname: 'Viola', Name: 'Antonio', Department: 'Sicurezza', Badge: '321654' },
                                ].map((group, index) => (
                                    <tr key={index}>
                                        <td>{group.Surname}</td>
                                        <td>{group.Name}</td>
                                        <td>{group.Department}</td>
                                        <td>{group.Badge}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </div>

                </div>

            </div>

        </section>
    );
}

export default SingleLevelGroup;