import React, { useEffect, useRef } from 'react';

interface IProps {
    visible: boolean;
    x: number;
    y: number;
    position: { horizontal: 'left' | 'right'; vertical: 'top' | 'bottom' };
    menu: { title: string, onClick: () => void, icon: JSX.Element }[];
    onClose: () => void;
}

function ActionsPopup({ visible, x, y, position, menu, onClose }: IProps) {
    const popupRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const handleClickOutside = (e: MouseEvent) => {
            // Chiudi il popup se visibile e si clicca fuori
            if (visible && popupRef.current && !popupRef.current.contains(e.target as Node)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [visible, onClose]);

    if (!visible) return null;
    return (
        <div
            ref={popupRef}
            className={`absolute z-10 bg-white shadow-lg border rounded-md p-2 w-48`}
            style={{
                left: position.horizontal === 'left' ? x - 192 : x,
                top: position.vertical === 'top' ? y - ((menu.length - 1) * 40) - 25 : y, // Regola l'altezza del popup secondo le necessità
            }}
        >
            <ul>
                {menu.map((item, index) => (
                    <li key={index} onClick={(e) => {
                        e.stopPropagation(); // Impedisce la propagazione del clic
                        item.onClick();
                        onClose();
                    }} className='flex items-center gap-3 p-2 hover:bg-gray-100 cursor-pointer'>
                        {item.icon} {item.title}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ActionsPopup;
