import React from 'react';
import { PageType } from '../types';
import LevelsTable from '../components/tables/LevelsTable';
import SingleLevel from '../components/SingleLevel';
import CreateLevel from '../components/CreateLevel';

interface IProps {
	type: PageType;
}

function Levels({ type }: IProps) {
	return (
		<section className='w-full h-full'>

			{type === PageType.ALL && <LevelsTable />}
			{type === PageType.SINGLE && <SingleLevel />}
			{type === PageType.CREATE && <CreateLevel />}

		</section>
	);
}

export default Levels;
