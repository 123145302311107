import React from 'react';
import { Link } from 'react-router-dom';

function GeneralStats() {
    return (
        <section className='w-full h-full p-6 bg-white rounded-2xl shadow-lg'>

            <h3 className='h3'>Statistiche generali</h3>

            <table className='my-3'>
                {/* <thead>
                    <tr>
                        <th>Livello</th>
                        <th>Tempo</th>
                        <th>Dipendente</th>
                    </tr>
                </thead> */}
                <tbody>
                    {[
                        { title: 'Numero di dipendenti', value: '12' },
                        { title: 'Pacchetti di Livelli', value: '8' },
                        { title: 'Pacchetti di Livelli in Bozza', value: '2' },
                        { title: 'Livelli totali', value: '15' },
                        { title: 'Livelli in Bozza', value: '5' },
                        { title: 'Livelli completati', value: '10' },
                        { title: 'Livelli non completati', value: '5' },
                        { title: 'Numero medio di Livelli per Pacchetto di Livelli', value: '4' },
                        { title: 'Tasso di risposta', value: '85%' },
                    ].map((user, index) => (
                        <tr key={index}>
                            <td className='text-wrap'>{user.title}</td>
                            <td>{user.value}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

        </section>
    );
}

export default GeneralStats;
