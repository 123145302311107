import { MdLogout } from 'react-icons/md';
import logo_account from '../../images/logo_account.jpg';

function ProfileCard() {
  return (
    <div className='p-3 bg-white text-black rounded-lg'>
      <div className='flex items-center justify-between gap-3'>
        <div className='flex items-center gap-3'>
          <figure className='w-12 h-12 rounded-full border border-black'>
            <img className='w-full h-full rounded-full' src={logo_account} alt='Marco Micci' />
          </figure>
          <div className=''>
            <p className='font-bold'>Marco Micci</p>
            <p className='text-sm'>Amministratore</p>
          </div>
        </div>
        <button className='text-red-500 hover:text-red-700' onClick={() => { }}>
          <MdLogout size={24} />
        </button>
      </div>
    </div>
  );
}

export default ProfileCard;