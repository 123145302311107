import React from 'react';
import EmployeesTable from '../components/tables/EmployeesTable';

function Employees() {
	return (
		<section className='w-full h-full'>
			
			<EmployeesTable />

		</section>
	);
}

export default Employees;
