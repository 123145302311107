import React, { useEffect, useState } from 'react';
import { MdAdd, MdDelete, MdEditNote, MdOpenInBrowser, MdOutlineClose, MdOutlineCopyAll, MdOutlineScreenShare, MdPublish, MdQueryStats, MdShare } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import ActionsPopup from '../popup/ActionsPopup';
import PaginationControls from '../utils/PaginationControls';
import { CiMenuKebab } from 'react-icons/ci';
import Notification from '../utils/Notification';
import SearchBar from '../utils/SearchBar';
import calculatePopupPosition from '../../utils/calculatePopupPosition';

interface IProps {
    isArchived?: boolean;
}

function LevelsTable(props: IProps) {
    const navigate = useNavigate();

    const [notification, setNotification] = useState<{ message: string; type: 'success' | 'error' | 'warning' } | null>(null);
    const [popupVisible, setPopupVisible] = useState(false);
    const [popupPosition, setPopupPosition] = useState({
        x: 0,
        y: 0,
        position: { horizontal: 'right' as 'right' | 'left', vertical: 'bottom' as 'top' | 'bottom' },
    });
    const [activeRowIndex, setActiveRowIndex] = useState<number | null>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [seletectedLevels, setSelectedLevels] = useState([] as any[]);

    const levels = [
        { Id: 1, Name: 'Livello 1', Status: 'Bozza', Goal: 'Quiz', NumGroups: 5, NumEmployees: 10, Rooms: { Loaded: 7, Total: 12 }, Link: 'https://example.com' },
        { Id: 2, Name: 'Livello 2', Status: 'Pubblicato', Goal: 'Raccolta oggetti', NumGroups: 3, NumEmployees: 5, Rooms: { Loaded: 12, Total: 12 }, Link: 'https://example.com' },
        { Id: 3, Name: 'Livello 3', Status: 'Pubblicato', Goal: 'Quiz', NumGroups: 2, NumEmployees: 3, Rooms: { Loaded: 0, Total: 12 }, Link: 'https://example.com' },
        { Id: 4, Name: 'Livello 4', Status: 'Bozza', Goal: 'Raggiungi stanza', NumGroups: 1, NumEmployees: 1, Rooms: { Loaded: 0, Total: 12 }, Link: 'https://example.com' },
        { Id: 5, Name: 'Livello 5', Status: 'Bozza', Goal: 'Quiz', NumGroups: 5, NumEmployees: 10, Rooms: { Loaded: 7, Total: 12 }, Link: 'https://example.com' },
        { Id: 6, Name: 'Livello 6', Status: 'Pubblicato', Goal: 'Stanza con oggetti', NumGroups: 3, NumEmployees: 5, Rooms: { Loaded: 12, Total: 12 }, Link: 'https://example.com' },
        { Id: 7, Name: 'Livello 7', Status: 'Pubblicato', Goal: 'Quiz', NumGroups: 2, NumEmployees: 3, Rooms: { Loaded: 0, Total: 12 }, Link: 'https://example.com' },
        { Id: 8, Name: 'Livello 8', Status: 'Bozza', Goal: 'Deposito oggetti', NumGroups: 1, NumEmployees: 1, Rooms: { Loaded: 0, Total: 12 }, Link: 'https://example.com' },
        { Id: 9, Name: 'Livello 9', Status: 'Bozza', Goal: 'Quiz', NumGroups: 5, NumEmployees: 10, Rooms: { Loaded: 7, Total: 12 }, Link: 'https://example.com' },
        { Id: 10, Name: 'Livello 10', Status: 'Pubblicato', Goal: 'Stanza con oggetti', NumGroups: 3, NumEmployees: 5, Rooms: { Loaded: 12, Total: 12 }, Link: 'https://example.com' },
        { Id: 11, Name: 'Livello 11', Status: 'Pubblicato', Goal: 'Quiz', NumGroups: 2, NumEmployees: 3, Rooms: { Loaded: 0, Total: 12 }, Link: 'https://example.com' },
        { Id: 12, Name: 'Livello 12', Status: 'Bozza', Goal: 'Raccolta oggetti', NumGroups: 1, NumEmployees: 1, Rooms: { Loaded: 0, Total: 12 }, Link: 'https://example.com' },
        { Id: 13, Name: 'Livello 13', Status: 'Bozza', Goal: 'Quiz', NumGroups: 5, NumEmployees: 10, Rooms: { Loaded: 7, Total: 12 }, Link: 'https://example.com' },
        { Id: 14, Name: 'Livello 14', Status: 'Pubblicato', Goal: 'Raggiungi stanza', NumGroups: 3, NumEmployees: 5, Rooms: { Loaded: 12, Total: 12 }, Link: 'https://example.com' },
        { Id: 15, Name: 'Livello 15', Status: 'Pubblicato', Goal: 'Quiz', NumGroups: 2, NumEmployees: 3, Rooms: { Loaded: 0, Total: 12 }, Link: 'https://example.com' },
        { Id: 16, Name: 'Livello 16', Status: 'Bozza', Goal: 'Raccolta oggetti', NumGroups: 1, NumEmployees: 1, Rooms: { Loaded: 0, Total: 12 }, Link: 'https://example.com' },
    ];

    useEffect(() => {
        if (searchTerm) {
            setSelectedLevels(levels.filter(employee => {
                return employee.Name.toLowerCase().includes(searchTerm.toLowerCase());
            }));
        } else {
            setSelectedLevels(levels);
        }
    }, [searchTerm]);

    const showNotification = (message: string, type: 'success' | 'error' | 'warning') => {
        setNotification({ message, type });
    };

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                showNotification("Link copiato negli appunti!", 'success');
            })
            .catch((error) => {
                showNotification(`Errore nella copia del link: ${error}`, 'error');
            });
    };

    const handleIconClick = (e: React.MouseEvent, index: number) => {
        if (popupVisible && activeRowIndex === index) {
            // Se il popup è già visibile per questa riga, chiudilo
            setPopupVisible(false);
            setActiveRowIndex(null);
            return;
        }

        // Use the utility function to calculate the position
        const position = calculatePopupPosition(e);

        setPopupPosition(position);
        setPopupVisible(true);
        setActiveRowIndex(index);
    };

    const handleClosePopup = () => {
        setPopupVisible(false);
        setActiveRowIndex(null);
    };

    return (
        <section>

            {notification && (
                <Notification
                    message={notification.message}
                    type={notification.type}
                    onClose={() => setNotification(null)}
                />
            )}

            <div className='grid grid-cols-3 items-center mb-6'>
                <div className='col-span-1'>
                    <h1 className='h1'>Livelli</h1>
                </div>
                <div className='col-span-2 flex items-center gap-5'>
                    <SearchBar value={searchTerm} onChange={setSearchTerm} />
                    <Link className='btn' to={'/levels/create'}>Aggiungi <MdAdd size={18} /></Link>
                </div>
            </div>

            <table>
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Stato</th>
                        <th>Obiettivo</th>
                        <th>Pacchetti</th>
                        <th>Dipendenti</th>
                        <th>Stanze</th>
                        <th>Link Test</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {seletectedLevels.map((level, index) => (
                        <tr key={index}
                            className='hover:bg-gray-200 cursor-pointer'
                            onClick={() => { navigate(`${level.Id}`) }}
                        >
                            <td>{level.Name}</td>
                            <td className='italic'>{level.Status}</td>
                            <td>{level.Goal}</td>
                            <td>{level.NumGroups}</td>
                            <td>{level.NumEmployees}</td>
                            <td>{level.Rooms.Loaded} / {level.Rooms.Total} {level.Rooms.Loaded === level.Rooms.Total ? '✅' : (level.Rooms.Loaded === 0 ? '❌' : '🕒')}</td>
                            <td className='flex items-center justify-center gap-3'>
                                <Link to={level.Link} target='_blank'>
                                    <MdOpenInBrowser size={28} />
                                </Link>
                                <button onClick={() => copyToClipboard(level.Link)}>
                                    <MdOutlineCopyAll size={28} />
                                </button>
                            </td>
                            <td>
                                <button onClick={(e) => handleIconClick(e, index)}>
                                    {popupVisible && activeRowIndex === index ? (
                                        <MdOutlineClose size={28} /> // Mostra l'icona "X" se il popup è visibile per questa riga
                                    ) : (
                                        <CiMenuKebab size={28} /> // Mostra i tre puntini altrimenti
                                    )}
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <PaginationControls page={1} totalPages={10} onPageChange={() => { }} />

            <ActionsPopup
                visible={popupVisible}
                x={popupPosition.x}
                y={popupPosition.y}
                position={popupPosition.position}
                menu={[
                    { title: 'Modifica', onClick: () => { showNotification('modifica', 'warning') }, icon: <MdEditNote size={24} /> },
                    { title: 'Pubblica', onClick: () => { showNotification('pubblica', 'warning') }, icon: <MdOutlineScreenShare size={24} /> },
                    { title: 'Statistiche', onClick: () => { showNotification('statistiche', 'warning') }, icon: <MdQueryStats size={24} /> },
                    { title: 'Elimina', onClick: () => { showNotification('elimina', 'warning') }, icon: <MdDelete size={24} /> },
                ]}
                onClose={handleClosePopup}
            />
        </section>
    );
}

export default LevelsTable;
