import React from 'react';
import PaginationControls from '../utils/PaginationControls';
import ProgressBar from '../utils/ProgressBar';
import { Link } from 'react-router-dom';
import Tooltip from '../utils/Tooltip';

function LevelsGroup() {
    return (
        <section className='w-full h-full p-6 bg-white rounded-2xl shadow-lg'>

            <div className='w-full flex items-center justify-between'>
                <h3 className='h3'>Pacchetti di Livelli</h3>
                <div className='flex items-center gap-3'>
                    <span>Mostra</span>
                    <select className='border border-gray-300 rounded-md px-1' value={10} onChange={() => { }}>
                        <option value='5'>5</option>
                        <option value='10'>10</option>
                        <option value='15'>15</option>
                        <option value='20'>20</option>
                    </select>
                </div>
            </div>

            <table className='my-3'>
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Avanzamento</th>
                    </tr>
                </thead>
                <tbody>
                    {[
                        { name: 'Pacchetto 1', progress: '50%' },
                        { name: 'Pacchetto 2', progress: '30%' },
                        { name: 'Pacchetto 3', progress: '70%' },
                        { name: 'Pacchetto 4', progress: '90%' },
                        { name: 'Pacchetto 5', progress: '40%' },
                        { name: 'Pacchetto 6', progress: '60%' },
                        { name: 'Pacchetto 7', progress: '80%' },
                    ].map((user, index) => (
                        <tr key={index}>
                            <td>{user.name}</td>
                            <td>
                                <Tooltip text={user.progress} position='top'>
                                    <ProgressBar progress={user.progress} />
                                </Tooltip>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* <PaginationControls page={1} totalPages={10} onPageChange={() => {}} /> */}

            <div className='flex justify-center'>
                <button className='btn'>Mostra tutti</button>
            </div>

        </section>
    );
}

export default LevelsGroup;