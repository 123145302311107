import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Notification from './utils/Notification';
import { MdDelete, MdEdit, MdEditNote, MdOpenInBrowser, MdOutlineCopyAll, MdOutlineScreenShare } from 'react-icons/md';

function SingleLevel() {
    const { id } = useParams();

    const [notification, setNotification] = useState<{ message: string; type: 'success' | 'error' | 'warning' } | null>(null);

    const showNotification = (message: string, type: 'success' | 'error' | 'warning') => {
        setNotification({ message, type });
    };

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                showNotification("Link copiato negli appunti!", 'success');
            })
            .catch((error) => {
                showNotification(`Errore nella copia del link: ${error}`, 'error');
            });
    };

    return (
        <section>

            {notification && (
                <Notification
                    message={notification.message}
                    type={notification.type}
                    onClose={() => setNotification(null)}
                />
            )}

            <h1 className='h1'>Livello</h1>

            <div className='flex items-center justify-between my-6'>
                <div className='flex items-center gap-3'>
                    <h5 className='h5'>Livello:</h5>
                    <span>Livello {id}</span>
                </div>
                <div className='flex items-center gap-3'>
                    <h5 className='h5'>Obiettivo:</h5>
                    <span>Quiz</span>
                </div>
                <div className='flex items-center gap-3'>
                    <h5 className='h5'>Link test:</h5>
                    <div className='flex items-center justify-center gap-3'>
                        <Link to={'https://example.com'} target='_blank'>
                            <MdOpenInBrowser size={28} />
                        </Link>
                        <button onClick={() => copyToClipboard('https://example.com')}>
                            <MdOutlineCopyAll size={28} />
                        </button>
                    </div>
                </div>
            </div>

            <div className='grid grid-cols-3 gap-6'>

                <div className='col-span-2'>

                    <div className='w-full p-6 bg-white rounded-2xl shadow-lg'>

                        <div className='w-full flex items-center justify-between'>
                            <h3 className='h3'>Elenco Pacchetti di Livelli associati</h3>
                            <button className='btn'>Modifica <MdEdit size={18} /></button>
                        </div>

                        <table className='my-3'>
                            <thead>
                                <tr>
                                    <th>Pacchetto</th>
                                    <th>Descrizione</th>
                                </tr>
                            </thead>
                            <tbody>
                                {[
                                    { name: 'Pacchetto 1', description: 'Descrizione pacchetto di livelli 1' },
                                    { name: 'Pacchetto 2', description: 'Descrizione pacchetto di livelli 2' },
                                    { name: 'Pacchetto 3', description: 'Descrizione pacchetto di livelli 3' },
                                    { name: 'Pacchetto 4', description: 'Descrizione pacchetto di livelli 4' },
                                    { name: 'Pacchetto 5', description: 'Descrizione pacchetto di livelli 5' },
                                    { name: 'Pacchetto 6', description: 'Descrizione pacchetto di livelli 6' },
                                    { name: 'Pacchetto 7', description: 'Descrizione pacchetto di livelli 7' },
                                ].map((group, index) => (
                                    <tr key={index}>
                                        <td>{group.name}</td>
                                        <td>{group.description}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </div>

                    <div className='w-full p-6 bg-white rounded-2xl shadow-lg mt-6'>

                        <div className='w-full flex items-center justify-between'>
                            <h3 className='h3'>Elenco Dipendenti associati</h3>
                            <button className='btn'>Modifica <MdEdit size={18} /></button>
                        </div>

                        <table className='my-3'>
                            <thead>
                                <tr>
                                    <th>Cognome</th>
                                    <th>Nome</th>
                                    <th>Reparto</th>
                                    <th>Matricola</th>
                                </tr>
                            </thead>
                            <tbody>
                                {[
                                    { Surname: 'Rossi', Name: 'Mario', Department: 'Amministrazione', Badge: '123456' },
                                    { Surname: 'Verdi', Name: 'Luca', Department: 'Produzione', Badge: '654321' },
                                    { Surname: 'Bianchi', Name: 'Paolo', Department: 'Vendite', Badge: '456789' },
                                    { Surname: 'Gialli', Name: 'Giovanni', Department: 'Marketing', Badge: '987654' },
                                    { Surname: 'Neri', Name: 'Andrea', Department: 'Risorse Umane', Badge: '789456' },
                                    { Surname: 'Arancioni', Name: 'Giuseppe', Department: 'Logistica', Badge: '654987' },
                                    { Surname: 'Viola', Name: 'Antonio', Department: 'Sicurezza', Badge: '321654' },
                                ].map((group, index) => (
                                    <tr key={index}>
                                        <td>{group.Surname}</td>
                                        <td>{group.Name}</td>
                                        <td>{group.Department}</td>
                                        <td>{group.Badge}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </div>

                </div>

                <div className='col-span-1 flex flex-col gap-6'>

                    <div className='w-full p-6 bg-white rounded-2xl shadow-lg'>

                        <div className='flex flex-col items-center justify-center gap-4'>
                            <div className='flex items-center gap-3'>
                                <h5 className='h5'>Numero di Stanze:</h5>
                                <span>10</span>
                            </div>

                            <div className='flex items-center gap-3'>
                                <h5 className='h5'>Stanze Caricate:</h5>
                                <span>7</span>
                            </div>

                            <button className='btn'>Gestisci stanze</button>
                        </div>

                    </div>

                    <button className='w-full btn btn-lg'>Modifica <MdEdit size={20} /></button>
                    <button className='w-full btn btn-lg'>Pubblica <MdOutlineScreenShare size={20} /></button>
                    <button className='w-full btn btn-lg btn-danger'>Elimina <MdDelete size={20} /></button>

                </div>

            </div>

        </section>
    );
}

export default SingleLevel;