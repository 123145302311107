import React, { useEffect, useState } from 'react';
import { MdAdd, MdDelete, MdEditNote, MdOpenInBrowser, MdOutlineClose, MdOutlineCopyAll, MdOutlineScreenShare, MdPublish, MdQueryStats, MdShare } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import ActionsPopup from '../popup/ActionsPopup';
import PaginationControls from '../utils/PaginationControls';
import { CiMenuKebab } from 'react-icons/ci';
import Notification from '../utils/Notification';
import SearchBar from '../utils/SearchBar';
import calculatePopupPosition from '../../utils/calculatePopupPosition';

interface IProps {
    isArchived?: boolean;
}

function LevelsGroupGroupsTable(props: IProps) {
    const navigate = useNavigate();

    const [notification, setNotification] = useState<{ message: string; type: 'success' | 'error' | 'warning' } | null>(null);
    const [popupVisible, setPopupVisible] = useState(false);
    const [popupPosition, setPopupPosition] = useState({
        x: 0,
        y: 0,
        position: { horizontal: 'right' as 'right' | 'left', vertical: 'bottom' as 'top' | 'bottom' },
    });
    const [activeRowIndex, setActiveRowIndex] = useState<number | null>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [seletectedLevelsGroup, setSelectedLevelsGroup] = useState([] as any[]);

    const levelsGroup = [
        { Id: 1, Name: 'Pacchetto 1', Status: 'Bozza', NumLevels: 5, NumEmployees: 10 },
        { Id: 2, Name: 'Pacchetto 2', Status: 'Pubblicato', NumLevels: 3, NumEmployees: 5 },
        { Id: 3, Name: 'Pacchetto 3', Status: 'Pubblicato', NumLevels: 2, NumEmployees: 3 },
        { Id: 4, Name: 'Pacchetto 4', Status: 'Bozza', NumLevels: 1, NumEmployees: 1 },
        { Id: 5, Name: 'Pacchetto 5', Status: 'Bozza', NumLevels: 5, NumEmployees: 10 },
        { Id: 6, Name: 'Pacchetto 6', Status: 'Pubblicato', NumLevels: 3, NumEmployees: 5 },
        { Id: 7, Name: 'Pacchetto 7', Status: 'Pubblicato', NumLevels: 2, NumEmployees: 3 },
        { Id: 8, Name: 'Pacchetto 8', Status: 'Bozza', NumLevels: 1, NumEmployees: 1 },
        { Id: 9, Name: 'Pacchetto 9', Status: 'Bozza', NumLevels: 5, NumEmployees: 10 },
        { Id: 10, Name: 'Pacchetto 10', Status: 'Pubblicato', NumLevels: 3, NumEmployees: 5 },
        { Id: 11, Name: 'Pacchetto 11', Status: 'Pubblicato', NumLevels: 2, NumEmployees: 3 },
        { Id: 12, Name: 'Pacchetto 12', Status: 'Bozza', NumLevels: 1, NumEmployees: 1 },
        { Id: 13, Name: 'Pacchetto 13', Status: 'Bozza', NumLevels: 5, NumEmployees: 10 },
        { Id: 14, Name: 'Pacchetto 14', Status: 'Pubblicato', NumLevels: 3, NumEmployees: 5 },
        { Id: 15, Name: 'Pacchetto 15', Status: 'Pubblicato', NumLevels: 2, NumEmployees: 3 },
        { Id: 16, Name: 'Pacchetto 16', Status: 'Bozza', NumLevels: 1, NumEmployees: 1 },
    ];

    useEffect(() => {
        if (searchTerm) {
            setSelectedLevelsGroup(levelsGroup.filter(employee => {
                return employee.Name.toLowerCase().includes(searchTerm.toLowerCase());
            }));
        } else {
            setSelectedLevelsGroup(levelsGroup);
        }
    }, [searchTerm]);

    const showNotification = (message: string, type: 'success' | 'error' | 'warning') => {
        setNotification({ message, type });
    };

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                showNotification("Link copiato negli appunti!", 'success');
            })
            .catch((error) => {
                showNotification(`Errore nella copia del link: ${error}`, 'error');
            });
    };

    const handleIconClick = (e: React.MouseEvent, index: number) => {
        if (popupVisible && activeRowIndex === index) {
            // Se il popup è già visibile per questa riga, chiudilo
            setPopupVisible(false);
            setActiveRowIndex(null);
            return;
        }

        // Use the utility function to calculate the position
        const position = calculatePopupPosition(e);

        setPopupPosition(position);
        setPopupVisible(true);
        setActiveRowIndex(index);
    };

    const handleClosePopup = () => {
        setPopupVisible(false);
        setActiveRowIndex(null);
    };

    return (
        <section>

            {notification && (
                <Notification
                    message={notification.message}
                    type={notification.type}
                    onClose={() => setNotification(null)}
                />
            )}

            <div className='grid grid-cols-2 items-center mb-6'>
                <div className='col-span-1'>
                    <h1 className='h1'>Pacchetti di Livelli</h1>
                </div>
                <div className='col-span-1 flex items-center gap-5'>
                    <SearchBar value={searchTerm} onChange={setSearchTerm} />
                    <Link className='btn' to={'/levels/groups/create'}>Aggiungi <MdAdd size={18} /></Link>
                </div>
            </div>

            <table>
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Stato</th>
                        <th>Livelli</th>
                        <th>Dipendenti</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {seletectedLevelsGroup.map((level, index) => (
                        <tr key={index}
                        className='hover:bg-gray-200 cursor-pointer'
                        onClick={() => { navigate(`${level.Id}`) }}
                        >
                            <td>{level.Name}</td>
                            <td className='italic'>{level.Status}</td>
                            <td>{level.NumLevels}</td>
                            <td>{level.NumEmployees}</td>
                            <td>
                                <button onClick={(e) => handleIconClick(e, index)}>
                                    {popupVisible && activeRowIndex === index ? (
                                        <MdOutlineClose size={28} /> // Mostra l'icona "X" se il popup è visibile per questa riga
                                    ) : (
                                        <CiMenuKebab size={28} /> // Mostra i tre puntini altrimenti
                                    )}
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <PaginationControls page={1} totalPages={10} onPageChange={() => { }} />

            <ActionsPopup
                visible={popupVisible}
                x={popupPosition.x}
                y={popupPosition.y}
                position={popupPosition.position}
                menu={[
                    { title: 'Modifica', onClick: () => { showNotification('modifica', 'warning') }, icon: <MdEditNote size={24} /> },
                    { title: 'Pubblica', onClick: () => { showNotification('pubblica', 'warning') }, icon: <MdOutlineScreenShare size={24} /> },
                    { title: 'Statistiche', onClick: () => { showNotification('statistiche', 'warning') }, icon: <MdQueryStats size={24} /> },
                    { title: 'Elimina', onClick: () => { showNotification('elimina', 'warning') }, icon: <MdDelete size={24} /> },
                ]}
                onClose={handleClosePopup}
            />
        </section>
    );
}

export default LevelsGroupGroupsTable;
