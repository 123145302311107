import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Sidebar from './components/sidebar/Sidebar';
import ProtectedRoute from './pages/ProtectedRoute';
import Home from './pages/Home';
import Employees from './pages/Employees';
import Statistics from './pages/Statistics';
import Levels from './pages/Levels';
import LevelsGroups from './pages/LevelsGroups';
import { PageType } from './types';

function AppRoutes() {
  // const isAuthenticated = useAppSelector((state) => !!state.auth.token);
  const isAuthenticated = true;

  return (
    <Router>

      <div className='h-svh grid grid-cols-5 3xl:grid-cols-7 gap-6 pt-6 pr-6'>
        <div className='col-span-1'>
          <Sidebar />
        </div>

        <div className='col-span-4 3xl:col-span-6'>
          <Routes>
            {/* <Route path="/login" element={<Login />} />
            <Route path="/reset-password" element={<ResetPassword />} /> */}
            <Route path="/" element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Home />
              </ProtectedRoute>
            } />
            <Route path="/employees" element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Employees />
              </ProtectedRoute>
            } />
            <Route path="/levels" element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Levels type={PageType.ALL} />
              </ProtectedRoute>
            } />
            <Route path="/levels/create" element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Levels type={PageType.CREATE} />
              </ProtectedRoute>
            } />
            <Route path="/levels/:id" element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Levels type={PageType.SINGLE} />
              </ProtectedRoute>
            } />
            <Route path="/levels/groups" element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <LevelsGroups type={PageType.ALL} />
              </ProtectedRoute>
            } />
            <Route path="/levels/groups/:id" element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <LevelsGroups type={PageType.SINGLE} />
              </ProtectedRoute>
            } />
            <Route path="/statistics" element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Statistics />
              </ProtectedRoute>
            } />
          </Routes>
        </div>
      </div>

    </Router>
  );
}

function App() {
  return (
    // <Provider store={store}>
    <AppRoutes />
    // </Provider>
  );
}

export default App;
