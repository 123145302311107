import React, { useEffect, useState } from 'react';
import Stepper from './utils/Stepper';
import { MdAdd, MdChevronLeft, MdChevronRight, MdDelete, MdDownload, MdOutlineFileUpload, MdSave } from 'react-icons/md';
import { BiWorld } from 'react-icons/bi';
import SearchBar from './utils/SearchBar';

function CreateLevel() {
    const [currentStep, setCurrentStep] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedEmployees, setSelectedEmployees] = useState([] as any[]);
    const [isDragging, setIsDragging] = useState(false);
    const [dragStartIndex, setDragStartIndex] = useState<number | null>(null);
    const [initialSelectedEmployees, setInitialSelectedEmployees] = useState<any[]>([]);

    const steps = ['Informazioni', 'Stanze', 'Dipendenti'];
    const employees = [
        { Sex: 'M', Surname: 'Rossi', Name: 'Mario', Department: 'Alimentari', Badge: '123456', Levels: { Completed: 12, Waiting: 8, Failed: 2 }, LastAccess: '01/01/1990 | 12:45' },
        { Sex: 'F', Surname: 'Bianchi', Name: 'Luigi', Department: 'Elettronica', Badge: '456123', Levels: { Completed: 10, Waiting: 10, Failed: 0 }, LastAccess: '01/01/1990 | 12:45' },
        { Sex: 'M', Surname: 'Verdi', Name: 'Giuseppe', Department: 'Informatica', Badge: '789123', Levels: { Completed: 15, Waiting: 5, Failed: 1 }, LastAccess: '01/01/1990 | 12:45' },
        { Sex: 'F', Surname: 'Neri', Name: 'Giovanni', Department: 'Alimentari', Badge: '101123', Levels: { Completed: 20, Waiting: 0, Failed: 0 }, LastAccess: '01/01/1990 | 12:45' },
        { Sex: 'F', Surname: 'Gialli', Name: 'Antonio', Department: 'Elettronica', Badge: '112369', Levels: { Completed: 5, Waiting: 15, Failed: 0 }, LastAccess: '01/01/1990 | 12:45' },
        { Sex: 'M', Surname: 'Rossi', Name: 'Mario', Department: 'Alimentari', Badge: '123567', Levels: { Completed: 12, Waiting: 8, Failed: 2 }, LastAccess: '01/01/1990 | 12:45' },
        { Sex: 'F', Surname: 'Bianchi', Name: 'Luigi', Department: 'Elettronica', Badge: '456234', Levels: { Completed: 10, Waiting: 10, Failed: 0 }, LastAccess: '01/01/1990 | 12:45' },
        { Sex: 'M', Surname: 'Verdi', Name: 'Giuseppe', Department: 'Informatica', Badge: '789234', Levels: { Completed: 15, Waiting: 5, Failed: 1 }, LastAccess: '01/01/1990 | 12:45' },
        { Sex: 'F', Surname: 'Neri', Name: 'Giovanni', Department: 'Alimentari', Badge: '101234', Levels: { Completed: 20, Waiting: 0, Failed: 0 }, LastAccess: '01/01/1990 | 12:45' },
        { Sex: 'F', Surname: 'Gialli', Name: 'Antonio', Department: 'Elettronica', Badge: '112678', Levels: { Completed: 5, Waiting: 15, Failed: 0 }, LastAccess: '01/01/1990 | 12:45' },
        { Sex: 'M', Surname: 'Rossi', Name: 'Mario', Department: 'Alimentari', Badge: '123678', Levels: { Completed: 12, Waiting: 8, Failed: 2 }, LastAccess: '01/01/1990 | 12:45' },
        { Sex: 'F', Surname: 'Bianchi', Name: 'Luigi', Department: 'Elettronica', Badge: '456345', Levels: { Completed: 10, Waiting: 10, Failed: 0 }, LastAccess: '01/01/1990 | 12:45' },
        { Sex: 'M', Surname: 'Verdi', Name: 'Giuseppe', Department: 'Informatica', Badge: '789345', Levels: { Completed: 15, Waiting: 5, Failed: 1 }, LastAccess: '01/01/1990 | 12:45' },
        { Sex: 'F', Surname: 'Neri', Name: 'Giovanni', Department: 'Alimentari', Badge: '101345', Levels: { Completed: 20, Waiting: 0, Failed: 0 }, LastAccess: '01/01/1990 | 12:45' },
        { Sex: 'F', Surname: 'Gialli', Name: 'Antonio', Department: 'Elettronica', Badge: '112246', Levels: { Completed: 5, Waiting: 15, Failed: 0 }, LastAccess: '01/01/1990 | 12:45' },
    ];

    // useEffect(() => {
    //     if (searchTerm) {
    //         setSelectedEmployees(employees.filter(employee => {
    //             return employee.Surname.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //                 employee.Name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //                 employee.Department.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //                 employee.Badge.toLowerCase().includes(searchTerm.toLowerCase());
    //         }));
    //     } /* else {
    //         setSelectedEmployees(employees);
    //     } */
    // }, [searchTerm]);

    const handleNext = () => {
        if (currentStep < steps.length - 1) {
            setCurrentStep(prev => prev + 1);
        }
    };

    const handleBack = () => {
        if (currentStep > 0) {
            setCurrentStep(prev => prev - 1);
        }
    };

    const handleEmployeeSelect = (employee: any) => {
        setSelectedEmployees(prevSelected => {
            const isSelected = prevSelected.some(e => e.Badge === employee.Badge);
            if (isSelected) {
                // Remove if already selected
                return prevSelected.filter(e => e.Badge !== employee.Badge);
            } else {
                // Add if not selected
                return [...prevSelected, employee];
            }
        });
    };

    const handleMouseDown = (index: number) => {
        setIsDragging(true);
        setDragStartIndex(index);
        setInitialSelectedEmployees(selectedEmployees); // Capture the state before the drag starts
    };

    const handleMouseUp = () => {
        setIsDragging(false);
        setDragStartIndex(null);
    };

    const handleMouseEnter = (index: number) => {
        if (isDragging && dragStartIndex !== null) {
            // Determine drag direction
            const startIndex = Math.min(dragStartIndex, index);
            const endIndex = Math.max(dragStartIndex, index);
            const isDraggingBackward = index < dragStartIndex;

            // Get the range of employees being interacted with
            const employeesInRange = employees.slice(startIndex, endIndex + 1);

            setSelectedEmployees(() => {
                // If dragging forward, add employees to the selection
                if (!isDraggingBackward) {
                    return Array.from(new Set([...initialSelectedEmployees, ...employeesInRange]));
                }

                // If dragging backward, remove employees from the selection
                return initialSelectedEmployees.filter(
                    employee => !employeesInRange.some(e => e.Badge === employee.Badge)
                );
            });
        }
    };

    return (
        <section>

            <h1 className='h1'>Creazione Livello</h1>

            <div className='my-6'>
                <Stepper steps={steps} currentStep={currentStep} />
            </div>

            {currentStep === 0 && (
                <div className='w-full flex flex-col gap-4 p-6 bg-white rounded-2xl shadow-lg'>

                    <h3 className='h3'>Informazioni</h3>

                    <div className='form-element !grid !grid-cols-7 gap-4'>
                        <label htmlFor='nome'>Nome livello</label>
                        <input type='text' id='nome' name='Name' className='col-span-6' />
                    </div>

                    <div className='form-element !grid !grid-cols-7 gap-4'>
                        <label htmlFor='group'>Pacchetto di livelli</label>
                        <div className='col-span-6 flex gap-3'>
                            <select id='group' name='Group' className='w-full'>
                                <option value='' selected disabled hidden>Seleziona...</option>
                                <option value='1'>Pacchetto 1</option>
                                <option value='2'>Pacchetto 2</option>
                                <option value='3'>Pacchetto 3</option>
                                <option value='4'>Pacchetto 4</option>
                                <option value='5'>Pacchetto 5</option>
                                <option value='6'>Pacchetto 6</option>
                                <option value='7'>Pacchetto 7</option>
                            </select>
                            <button><MdAdd size={18} /></button>
                        </div>
                    </div>

                    <div className='form-element !grid !grid-cols-7 gap-4'>
                        <label htmlFor='group'>Obiettivo</label>
                        <select id='group' name='Group' className='col-span-6'>
                            <option value='' selected disabled hidden>Seleziona...</option>
                            <option value='1'>Quiz</option>
                            <option value='2'>Raccolta oggetti</option>
                            <option value='3'>Deposito oggetti</option>
                            <option value='4'>Raggiungimento stanza</option>
                            <option value='5'>Raggiungimento stanza con oggetti</option>
                        </select>
                    </div>

                    <div className='form-element !grid !grid-cols-7 gap-4'>
                        <label htmlFor='rooms'>Numero di stanze</label>
                        <input type='number' id='rooms' name='Rooms' className='col-span-6' min={0} max={20} />
                    </div>

                </div>
            )}

            {currentStep === 1 && (
                <div className='w-full flex flex-col gap-6 p-6 bg-white rounded-2xl shadow-lg'>

                    <h3 className='h3'>Carica stanze</h3>

                    <div className='grid grid-cols-4 gap-4'>
                        {Array.from({ length: 8 }).map((_, index) => (
                            <div
                                key={index}
                                className='border rounded-lg p-4 flex flex-col items-center justify-center bg-white'
                            >
                                <span className='text-center font-bold'>
                                    {index === 0 ? 'Stanza 1 - Partenza' : `Stanza ${index + 1}`}
                                </span>
                                <div className='flex flex-col items-center gap-2 mt-2 mb-6'>
                                    <MdOutlineFileUpload size={80} />
                                    <input type='file' name='' id='' />
                                </div>
                                <button className='btn btn-danger'>
                                    <MdDelete size={24} />
                                </button>
                            </div>
                        ))}
                    </div>

                    <div className='form-element !grid !grid-cols-7 gap-4'>
                        <label htmlFor='link'>Link per test</label>
                        {/* <input type='text' id='link' name='Link' className='col-span-6' /> */}
                        <div className='col-span-6 flex items-center relative'>
                            <input type='text' id='link' name='Link' />
                            <span className='absolute right-2'>
                                <BiWorld size={20} />
                            </span>
                        </div>
                    </div>

                </div>
            )}

            {currentStep === 2 && (
                <div className='w-full flex flex-col gap-6 p-6 bg-white rounded-2xl shadow-lg'>

                    <div className='grid grid-cols-3 items-center'>
                        <div className='col-span-1'>
                            <h3 className='h3'>Dipendenti</h3>
                        </div>
                        <div className='col-span-2 flex items-center gap-5'>
                            <SearchBar value={searchTerm} onChange={setSearchTerm} />
                        </div>
                    </div>

                    <table>
                        <thead>
                            <tr>
                                <th>Selezionato</th>
                                <th>Cognome</th>
                                <th>Nome</th>
                                <th>Reparto</th>
                                <th>Matricola</th>
                                <th>Livelli</th>
                            </tr>
                        </thead>
                        <tbody>
                            {employees.map((employee, index) => {
                                const isSelected = selectedEmployees.some(e => e.Badge === employee.Badge);
                                return (
                                    <tr key={index}
                                        className={`hover:bg-gray-200 cursor-pointer ${isSelected ? '!bg-blue-200' : ''}`}
                                        onClick={() => handleEmployeeSelect(employee)}
                                        onMouseDown={() => handleMouseDown(index)}
                                        onMouseUp={handleMouseUp}
                                        onMouseEnter={() => handleMouseEnter(index)}
                                    >
                                        <td>{isSelected ? '✔️' : '❌'}</td>
                                        <td>{employee.Surname}</td>
                                        <td>{employee.Name}</td>
                                        <td>{employee.Department}</td>
                                        <td>{employee.Badge}</td>
                                        <td>{employee.Levels.Completed} ✅ | {employee.Levels.Waiting} 🕒 | {employee.Levels.Failed} ❌</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>

                </div>
            )}

            {/* Navigation buttons */}
            <div className='flex justify-between gap-4 my-6'>
                <button className='btn btn-danger' disabled={currentStep === 0} onClick={handleBack}>
                    <MdChevronLeft size={18} /> Indietro
                </button>
                {currentStep < steps.length - 1 ? (
                    <button className='btn' onClick={handleNext}>
                        Avanti <MdChevronRight size={18} />
                    </button>
                ) : (
                    <button className='btn btn-primary'>
                        Salva <MdSave size={18} />
                    </button>
                )}
            </div>

        </section>
    );
}

export default CreateLevel;