import React, { useEffect, useState } from 'react';
import { MdAdd, MdEditNote, MdLockReset, MdOutlineClose } from 'react-icons/md';
import { Link } from 'react-router-dom';
import ActionsPopup from '../popup/ActionsPopup';
import PaginationControls from '../utils/PaginationControls';
import { CiMenuKebab } from 'react-icons/ci';
import Notification from '../utils/Notification';
import SearchBar from '../utils/SearchBar';
import calculatePopupPosition from '../../utils/calculatePopupPosition';

interface IProps {
    isArchived?: boolean;
}

function EmployeesTable(props: IProps) {
    const [notification, setNotification] = useState<{ message: string; type: 'success' | 'error' | 'warning' } | null>(null);
    const [popupVisible, setPopupVisible] = useState(false);
    const [popupPosition, setPopupPosition] = useState({
        x: 0,
        y: 0,
        position: { horizontal: 'right' as 'right' | 'left', vertical: 'bottom' as 'top' | 'bottom' },
    });
    const [activeRowIndex, setActiveRowIndex] = useState<number | null>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedEmployees, setSelectedEmployees] = useState([] as any[]);

    const employees = [
        { Sex: 'M', Surname: 'Rossi', Name: 'Mario', Department: 'Alimentari', Badge: '123XXX', Levels: { Completed: 12, Waiting: 8, Failed: 2 }, LastAccess: '01/01/1990 | 12:45' },
        { Sex: 'F', Surname: 'Bianchi', Name: 'Luigi', Department: 'Elettronica', Badge: '456XXX', Levels: { Completed: 10, Waiting: 10, Failed: 0 }, LastAccess: '01/01/1990 | 12:45' },
        { Sex: 'M', Surname: 'Verdi', Name: 'Giuseppe', Department: 'Informatica', Badge: '789XXX', Levels: { Completed: 15, Waiting: 5, Failed: 1 }, LastAccess: '01/01/1990 | 12:45' },
        { Sex: 'F', Surname: 'Neri', Name: 'Giovanni', Department: 'Alimentari', Badge: '101XXX', Levels: { Completed: 20, Waiting: 0, Failed: 0 }, LastAccess: '01/01/1990 | 12:45' },
        { Sex: 'F', Surname: 'Gialli', Name: 'Antonio', Department: 'Elettronica', Badge: '112XXX', Levels: { Completed: 5, Waiting: 15, Failed: 0 }, LastAccess: '01/01/1990 | 12:45' },
        { Sex: 'M', Surname: 'Rossi', Name: 'Mario', Department: 'Alimentari', Badge: '123XXX', Levels: { Completed: 12, Waiting: 8, Failed: 2 }, LastAccess: '01/01/1990 | 12:45' },
        { Sex: 'F', Surname: 'Bianchi', Name: 'Luigi', Department: 'Elettronica', Badge: '456XXX', Levels: { Completed: 10, Waiting: 10, Failed: 0 }, LastAccess: '01/01/1990 | 12:45' },
        { Sex: 'M', Surname: 'Verdi', Name: 'Giuseppe', Department: 'Informatica', Badge: '789XXX', Levels: { Completed: 15, Waiting: 5, Failed: 1 }, LastAccess: '01/01/1990 | 12:45' },
        { Sex: 'F', Surname: 'Neri', Name: 'Giovanni', Department: 'Alimentari', Badge: '101XXX', Levels: { Completed: 20, Waiting: 0, Failed: 0 }, LastAccess: '01/01/1990 | 12:45' },
        { Sex: 'F', Surname: 'Gialli', Name: 'Antonio', Department: 'Elettronica', Badge: '112XXX', Levels: { Completed: 5, Waiting: 15, Failed: 0 }, LastAccess: '01/01/1990 | 12:45' },
        { Sex: 'M', Surname: 'Rossi', Name: 'Mario', Department: 'Alimentari', Badge: '123XXX', Levels: { Completed: 12, Waiting: 8, Failed: 2 }, LastAccess: '01/01/1990 | 12:45' },
        { Sex: 'F', Surname: 'Bianchi', Name: 'Luigi', Department: 'Elettronica', Badge: '456XXX', Levels: { Completed: 10, Waiting: 10, Failed: 0 }, LastAccess: '01/01/1990 | 12:45' },
        { Sex: 'M', Surname: 'Verdi', Name: 'Giuseppe', Department: 'Informatica', Badge: '789XXX', Levels: { Completed: 15, Waiting: 5, Failed: 1 }, LastAccess: '01/01/1990 | 12:45' },
        { Sex: 'F', Surname: 'Neri', Name: 'Giovanni', Department: 'Alimentari', Badge: '101XXX', Levels: { Completed: 20, Waiting: 0, Failed: 0 }, LastAccess: '01/01/1990 | 12:45' },
        { Sex: 'F', Surname: 'Gialli', Name: 'Antonio', Department: 'Elettronica', Badge: '112XXX', Levels: { Completed: 5, Waiting: 15, Failed: 0 }, LastAccess: '01/01/1990 | 12:45' },
    ];

    useEffect(() => {
        if (searchTerm) {
            setSelectedEmployees(employees.filter(employee => {
                return employee.Surname.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    employee.Name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    employee.Department.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    employee.Badge.toLowerCase().includes(searchTerm.toLowerCase());
            }));
        } else {
            setSelectedEmployees(employees);
        }
    }, [searchTerm]);

    const showNotification = (message: string, type: 'success' | 'error' | 'warning') => {
        setNotification({ message, type });
    };

    const handleIconClick = (e: React.MouseEvent, index: number) => {
        if (popupVisible && activeRowIndex === index) {
            // Se il popup è già visibile per questa riga, chiudilo
            setPopupVisible(false);
            setActiveRowIndex(null);
            return;
        }

        // Use the utility function to calculate the position
        const position = calculatePopupPosition(e);

        setPopupPosition(position);
        setPopupVisible(true);
        setActiveRowIndex(index);
    };

    const handleClosePopup = () => {
        setPopupVisible(false);
        setActiveRowIndex(null);
    };

    return (
        <section>

            {notification && (
                <Notification
                    message={notification.message}
                    type={notification.type}
                    onClose={() => setNotification(null)}
                />
            )}

            <div className='grid grid-cols-3 items-center mb-6'>
                <div className='col-span-1'>
                    <h1 className='h1'>Dipendenti</h1>
                </div>
                <div className='col-span-2 flex items-center gap-5'>
                    <SearchBar value={searchTerm} onChange={setSearchTerm} />
                    <button className='btn'>Aggiungi <MdAdd size={18} /></button>
                </div>
            </div>

            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>Cognome</th>
                        <th>Nome</th>
                        <th>Reparto</th>
                        <th>Matricola</th>
                        <th>Livelli</th>
                        <th>Ultimo accesso</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {selectedEmployees.map((employee, index) => (
                        <tr key={index}
                            // className='hover:bg-gray-200 cursor-pointer'
                            // onClick={() => { }}
                        >
                            <td>{employee.Sex === 'M' ? '👦🏻' : '👧🏻'}</td>
                            <td>{employee.Surname}</td>
                            <td>{employee.Name}</td>
                            <td>{employee.Department}</td>
                            <td>{employee.Badge}</td>
                            <td>{employee.Levels.Completed} ✅ | {employee.Levels.Waiting} 🕒 | {employee.Levels.Failed} ❌</td>
                            <td>{employee.LastAccess}</td>
                            <td>
                                <button onClick={(e) => handleIconClick(e, index)}>
                                    {popupVisible && activeRowIndex === index ? (
                                        <MdOutlineClose size={28} /> // Mostra l'icona "X" se il popup è visibile per questa riga
                                    ) : (
                                        <CiMenuKebab size={28} /> // Mostra i tre puntini altrimenti
                                    )}
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <PaginationControls page={1} totalPages={10} onPageChange={() => { }} />

            <ActionsPopup
                visible={popupVisible}
                x={popupPosition.x}
                y={popupPosition.y}
                position={popupPosition.position}
                menu={[
                    { title: 'Modifica', onClick: () => { showNotification('modifica', 'warning') }, icon: <MdEditNote size={24} /> },
                    { title: 'Reset password', onClick: () => { showNotification('reset password', 'warning') }, icon: <MdLockReset size={24} /> },
                ]}
                onClose={handleClosePopup}
            />
        </section>
    );
}

export default EmployeesTable;
