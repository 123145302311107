import React, { useState } from 'react';
import PaginationControls from './utils/PaginationControls';
import { MdEditNote, MdOpenInBrowser, MdOutlineClose, MdOutlineCopyAll, MdPersonAdd, MdUpload } from 'react-icons/md';
import { CiMenuKebab } from 'react-icons/ci';
import { Link } from 'react-router-dom';
import ActionsPopup from './popup/ActionsPopup';
import Notification from './utils/Notification';
import calculatePopupPosition from '../utils/calculatePopupPosition';

function LevelsToPublish() {
    const [notification, setNotification] = useState<{ message: string; type: 'success' | 'error' | 'warning' } | null>(null);
    const [popupVisible, setPopupVisible] = useState(false);
    const [popupPosition, setPopupPosition] = useState({
        x: 0,
        y: 0,
        position: { horizontal: 'right' as 'right' | 'left', vertical: 'bottom' as 'top' | 'bottom' },
    });
    const [activeRowIndex, setActiveRowIndex] = useState<number | null>(null);

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                showNotification("Link copiato negli appunti!", 'success');
            })
            .catch((error) => {
                showNotification(`Errore nella copia del link: ${error}`, 'error');
            });
    };

    const showNotification = (message: string, type: 'success' | 'error' | 'warning') => {
        setNotification({ message, type });
    };

    const handleIconClick = (e: React.MouseEvent, index: number) => {
        if (popupVisible && activeRowIndex === index) {
            // Se il popup è già visibile per questa riga, chiudilo
            setPopupVisible(false);
            setActiveRowIndex(null);
            return;
        }

        // Use the utility function to calculate the position
        const position = calculatePopupPosition(e);

        setPopupPosition(position);
        setPopupVisible(true);
        setActiveRowIndex(index);
    };

    const handleClosePopup = () => {
        setPopupVisible(false);
        setActiveRowIndex(null);
    };

    return (
        <section className='w-full h-full p-6 bg-white rounded-2xl shadow-lg'>

            {notification && (
                <Notification
                    message={notification.message}
                    type={notification.type}
                    onClose={() => setNotification(null)}
                />
            )}

            <div className='w-full flex items-center justify-between'>
                <h3 className='h3'>Livelli da pubblicare</h3>
                <button className='btn'>Livelli</button>
            </div>

            <table className='mt-3'>
                <thead>
                    <tr>
                        <th>Nome Livello</th>
                        <th>Obiettivo</th>
                        <th>Pacchetti</th>
                        <th>Dipendenti</th>
                        <th>Stanze caricate</th>
                        <th>Link Test</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {[
                        { name: 'Quiz Operaio', goal: 'Quiz', NumGroups: 3, NumEmployees: 11, rooms: { loaded: 4, total: 12 }, testLink: 'https://example.com' },
                        { name: 'Uscita Stanza', goal: 'Raggiungi Stanza', NumGroups: 2, NumEmployees: 6, rooms: { loaded: 1, total: 1 }, testLink: 'https://example.com' },
                        { name: 'Quiz Manager', goal: 'Quiz', NumGroups: 7, NumEmployees: 19, rooms: { loaded: 0, total: 5 }, testLink: 'https://example.com' },
                        { name: 'Accesso Cantiere', goal: 'Raccolta Oggetti', NumGroups: 1, NumEmployees: 8, rooms: { loaded: 2, total: 3 }, testLink: 'https://example.com' },
                    ].map((user, index) => (
                        <tr key={index}>
                            <td>{user.name}</td>
                            <td>{user.goal}</td>
                            <td>{user.NumGroups}</td>
                            <td>{user.NumEmployees}</td>
                            <td>{user.rooms.loaded} / {user.rooms.total} {user.rooms.loaded === user.rooms.total ? '✅' : (user.rooms.loaded === 0 ? '❌' : '🕒')}</td>
                            <td className='flex items-center justify-center gap-3'>
                                <Link to={user.testLink} target='_blank'>
                                    <MdOpenInBrowser size={28} />
                                </Link>
                                <button onClick={() => copyToClipboard(user.testLink)}>
                                    <MdOutlineCopyAll size={28} />
                                </button>
                            </td>
                            <td>
                                <button onClick={(e) => handleIconClick(e, index)}>
                                    {popupVisible && activeRowIndex === index ? (
                                        <MdOutlineClose size={28} /> // Mostra l'icona "X" se il popup è visibile per questa riga
                                    ) : (
                                        <CiMenuKebab size={28} /> // Mostra i tre puntini altrimenti
                                    )}
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <PaginationControls page={1} totalPages={10} onPageChange={() => { }} />

            <ActionsPopup
                visible={popupVisible}
                x={popupPosition.x}
                y={popupPosition.y}
                position={popupPosition.position}
                menu={[
                    { title: 'Modifica', onClick: () => { showNotification('modifica', 'warning') }, icon: <MdEditNote size={24} /> },
                    { title: 'Assegna utenti', onClick: () => { showNotification('assegna utenti', 'warning') }, icon: <MdPersonAdd size={24} /> },
                    { title: 'Carica stanze', onClick: () => { showNotification('carica stanze', 'warning') }, icon: <MdUpload size={24} /> },
                ]}
                onClose={handleClosePopup}
            />

        </section>
    );
}

export default LevelsToPublish;